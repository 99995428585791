var app = {

	init: function() {
		app.core();
		// app.intro();
		// app.catalog();
		app.sliders();
		app.counter();
		// app.testimonials();
		// app.accordion();
		// app.animate();
	}, // init END


	core: function() {


		$('#menu-close').on('click', function() {
			$('.menu-modal').toggleClass('menu-open');
		});

		$('#menu-trigger').on('click', function() {
			$('.menu-modal').toggleClass('menu-open');
		});

		$('.main-menu-link').on('click', function() {
			$('.menu-modal').toggleClass('menu-open');
		});

		$(window).scroll(function(){
			var sticky = $('body'),
				scroll = $(window).scrollTop();


			// if (scroll >= 140) {
			// 	sticky.addClass('fixed-header')
			// } else {
			// 	sticky.removeClass('fixed-header');
			// }


			if (scroll > 140) {

				$('.page-header').addClass('fixed-header');

			} else {

				if (scroll <= 1) {

					$('.page-header').removeClass('fixed-header');

				}

			}

		});


	}, // core END

	sliders: function() {

		var $testimonials = new Swiper('#testimonial-slider', {
			slidesPerView: 1,
			speed: 400,
			spaceBetween: 20,
			// pagination: {
			// 	el: '.swiper-pagination',
			// 	type: 'bullets',
			// 	clickable: true,
			// },
			navigation: {
				nextEl: '.testimonial-button-next',
				prevEl: '.testimonial-button-prev',
			},
			breakpoints: {
				768: {
					slidesPerView: 2
				},
				992: {
					slidesPerView: 3
				},
				1440: {
					slidesPerView: 3
				}
			},
		});

		var $testimonials = new Swiper('#gallery-slider', {
			slidesPerView: 1,
			speed: 400,
			spaceBetween: 0,
			// pagination: {
			// 	el: '.gallery-pagination',
			// 	type: 'bullets',
			// 	clickable: true,
			// },
			navigation: {
				nextEl: '.gallery-button-next',
				prevEl: '.gallery-button-prev',
			},
		});

	}, // sliders END

	counter: function() {
	    function getTimeRemaining(endtime) {
	      var t = Date.parse(endtime) - Date.parse(new Date());
	      var seconds = Math.floor((t / 1000) % 60);
	      var minutes = Math.floor((t / 1000 / 60) % 60);
	      var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
	      var days = Math.floor(t / (1000 * 60 * 60 * 24));
	      return {
	        'total': t,
	        'days': days,
	        'hours': hours,
	        'minutes': minutes,
	        'seconds': seconds
	      };
	    }

	    function initializeClock(id, endtime) {
	      var clock = document.getElementById(id);
	      var daysSpan = clock.querySelector('.days');
	      var hoursSpan = clock.querySelector('.hours');
	      var minutesSpan = clock.querySelector('.minutes');
	      var secondsSpan = clock.querySelector('.seconds');

	      function updateClock() {
	        var t = getTimeRemaining(endtime);

	        // daysSpan.innerHTML = t.days;
	        hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
	        minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
	        secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

	        if (t.total <= 0) {
	          clearInterval(timeinterval);
	        }
	      }

	      updateClock();
	      var timeinterval = setInterval(updateClock, 1000);
	    }

	    var deadline = new Date(Date.parse(new Date()) + 15 * 24 * 60 * 60 * 1000);
	    initializeClock('clockdiv', deadline);
	}, // counter END

	accordion: function() {
		
		var $accordion_element = $('#accordion');
		if (!$accordion_element) {} else {

			$accordion_element.accordionjs({
				// Allow self close.(data-close-able)
				closeAble   : true,

				// Close other sections.(data-close-other)
				closeOther  : true,

				// Animation Speed.(data-slide-speed)
				slideSpeed  : 150,

				// The section open on first init. A number from 1 to X or false.(data-active-index)
				activeIndex : 1,

				// Callback when a section is open
				openSection: function( section ){},

				// Callback before a section is open
				beforeOpenSection: function( section ){},
			});
		}

	}, // accordion END


	animate: function() {
		
		

	} // accordion END
}

jQuery(document).ready(function($) {
	console.log( 'Документ и все ресурсы загружены' );
	// new WOW().init();
	app.init();
});
